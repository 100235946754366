import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import AudioList from '@components/audioList';
import Img from 'gatsby-image';
import styles from '@styles/pages/music.module.css';

const MusicPage = ({ data }) => {
    
    return (
        <Layout>
            <SEO title="Music" />
            <div className={styles.container}>
                <div className={styles.image}>
                    <Img fluid={data.musicImage.childImageSharp.fluid} alt={'Vinny Stodder in Music studio'} />
                </div>
                <div className={styles.playlist}>
                    <AudioList music={data.music} />
                </div>


            </div>

            <div className={styles.videos} dangerouslySetInnerHTML={{__html: data.videos.edges[0].node.html}}></div>
            <div className={styles.credits} dangerouslySetInnerHTML={{__html: data.credits.edges[0].node.html}}></div>

        </Layout>
    )
}

export default MusicPage


export const pageQuery = graphql`
{
    music: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/music\\\/(?!videos.md|credits.md)/"}}, sort: {fields: [frontmatter___order], order: ASC } ) {
        edges {
          node {
            html,
            frontmatter {
                order
                title
                show
                file {
                    publicURL
                }
                image {
                    childImageSharp {
                        fixed(width: 50) {
                          ...GatsbyImageSharpFixed
                        }
                    }
                }
              
            }
          }
        }
      }

    musicImage: file( relativePath: {eq: "vinny_studio_reversed.png"}) {
        childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
        }
    }


    videos: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/music\\\/videos.md/"}}) {
        edges {
          node {
            html
          }
        }
    }

    credits: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/music\\\/credits.md/"}}) {
        edges {
          node {
            html
          }
        }
    }
  
  }
`;

