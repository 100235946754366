import React from "react"
import Audio from '@components/audio';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';


class AudioList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentlyPlaying: "",
            currentRef: null,
            refs: props.music.edges.map(node => React.createRef())
        }

    }

    handlePlayTrack = (ref, title) => {
        this.state.refs.forEach((audioRef, ind) => {
            if (this.props.music.edges[ind].node.frontmatter.title !== title) {
                audioRef.current.audio.current.pause()
                audioRef.current.audio.current.currentTime = 0;
            }
        })

        trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Music",
            // string - required - Type of interaction (e.g. 'play')
            action: "Play",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: title,
        })

    }

    render() {
        const songs = this.props.music.edges.map((data, ind) => {
            let { frontmatter, html } = data.node;
            let { file, image, title, show } = frontmatter;
            let ref = this.state.refs[ind]
            return (
                <React.Fragment key={ind}>
                    <Audio playTrack={this.handlePlayTrack} ref={ref} song={file.publicURL} description={html} image={image} title={title} show={show} />
                </React.Fragment>
            );
        })
        return (
            <div >
                {songs}
            </div>
        );
    }

}



export default AudioList
